<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <operation-button
          name="Produtos"
          icon="box-seam"
          description="Lista de todos os produtos e suas informações"
          @click="getReport"
        />

        <operation-button
          name="Imagens do produto"
          icon="images"
          description="Lista de imagens dos produtos"
          @click="getImage"
        />

        <operation-button
          name="Produtos MagaLu"
          icon="bag-check"
          description="Modelo de importação de produtos para marketplace"
          @click="getMagalu"
        />

        <operation-button
          name="Roteiro"
          icon="clipboard-check"
          description="Resumo de produção das informações dos produtos"
          @click="getRoadMap"
        />

        <operation-button
          name="Mercado Livre"
          description="Modelo de importação de produtos para marketplace"
          disabled
        >
          <template #icon>
            <mercado-livre-icon />
          </template>
        </operation-button>

        <operation-button
          name="Amazon"
          disabled
          description="Modelo de importação de produtos para marketplace"
        >
          <template #icon>
            <amazon-icon />
          </template>
        </operation-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import http from "../plugins/http";
import OperationButton from "../components/button/OperationButton.vue";
import MercadoLivreIcon from "../components/icons/MercadoLivreIcon.vue";
import AmazonIcon from "../components/icons/AmazonIcon.vue";

export default {
  name: "exports",
  components: { OperationButton, MercadoLivreIcon, AmazonIcon },
  methods: {
    ...mapMutations(["setToasted"]),

    async getReport() {
      await http
        .get("/report/product", { responseType: "blob" })
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");

          a.href = url;
          a.download = `produto.xlsx`;
          a.click();
          a.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 100);

          this.setToasted({
            title: "Aguarde",
            message: "Relatório será enviado para seu email em instantes",
            variant: "success",
            visible: true,
          });
        })
        .catch(async (e) => {
          const text = await e.response.data.text();
          this.setToasted({
            title: "ERRO",
            message: JSON.parse(text).error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getRoadMap() {
      await http
        .get("/report/road-map", { responseType: "blob" })
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");

          a.href = url;
          a.download = `roteiro.xlsx`;
          a.click();
          a.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 100);

          this.setToasted({
            title: "Aguarde",
            message: "Relatório será enviado para seu email em instantes",
            variant: "success",
            visible: true,
          });
        })
        .catch(async (e) => {
          const text = await e.response.data.text();
          this.setToasted({
            title: "ERRO",
            message: JSON.parse(text).error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getImage() {
      await http
        .get("/report/image", { responseType: "blob" })
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");

          a.href = url;
          a.download = `imagens.xlsx`;
          a.click();
          a.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
          this.setToasted({
            title: "Aguarde",
            message: "Relatório será enviado para seu email em instantes",
            variant: "success",
            visible: true,
          });
        })
        .catch(async (e) => {
          const text = await e.response.data.text();
          this.setToasted({
            title: "ERRO",
            message: JSON.parse(text).error,
            variant: "danger",
            visible: true,
          });
        });
    },
    async getMagalu() {
      await http
        .get("/report/magalu", { responseType: "blob" })
        .then((res) => {
          const url = URL.createObjectURL(res.data);
          const a = document.createElement("a");

          a.href = url;
          a.download = `magalu.xlsx`;
          a.click();
          a.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
          this.setToasted({
            title: "Aguarde",
            message: "Relatório será enviado para seu email em instantes",
            variant: "success",
            visible: true,
          });
        })
        .catch(async (e) => {
          const text = await e.response.data.text();
          this.setToasted({
            title: "ERRO",
            message: JSON.parse(text).error,
            variant: "danger",
            visible: true,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
