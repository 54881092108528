<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M21.996 18.23c0 .727-.405 2.127-1.314 2.896c-.182.14-.365.061-.285-.143c.265-.648.872-2.147.587-2.492c-.2-.262-1.03-.243-1.738-.182c-.324.041-.607.06-.828.105c-.203.017-.245-.163-.041-.303c.262-.185.545-.325.87-.428c1.15-.344 2.48-.137 2.67.083c.036.042.08.16.08.463zm-1.921 1.294a7.426 7.426 0 0 1-.83.55c-2.122 1.275-4.87 1.943-7.258 1.943c-3.843 0-7.28-1.417-9.888-3.788c-.223-.182-.038-.446.223-.303c2.81 1.64 6.288 2.632 9.889 2.632a19.48 19.48 0 0 0 7.035-1.336c.162-.061.344-.144.503-.202c.367-.165.69.243.326.504zm-6.17-11.03c0-1.041.041-1.654-.304-2.18c-.306-.433-.833-.693-1.568-.652c-.798.044-1.655.567-1.874 1.526c-.042.22-.171.436-.436.483l-2.436-.31c-.174-.04-.438-.173-.352-.521C7.458 4.088 9.81 3.129 12.033 3h.523c1.22 0 2.787.349 3.79 1.264c1.217 1.136 1.088 2.662 1.088 4.32v3.927c0 1.178.477 1.7.958 2.314c.13.219.174.477-.045.655a76.07 76.07 0 0 1-1.917 1.654c-.174.133-.488.147-.61.045c-.77-.645-.958-1.003-1.435-1.658c-.83.871-1.526 1.352-2.355 1.613a7.035 7.035 0 0 1-1.784.216c-2.09 0-3.746-1.303-3.746-3.88c0-2.049 1.09-3.442 2.7-4.101c1.61-.66 3.95-.87 4.704-.874zm-.478 5.192c.52-.872.477-1.586.477-3.185c-.651 0-1.306.045-1.871.178c-1.045.303-1.874.961-1.874 2.355c0 1.09.567 1.832 1.525 1.832c.132 0 .248-.016.349-.045c.67-.186 1.088-.522 1.394-1.135z"
    />
  </svg>
</template>
<script>
export default {
  name: "AmazonIcon",
};
</script>
